import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RichText from '~components/RichText';
import Container from '~components/Container';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton';
import ListItem from '~modules/tisha-bav-event/components/shared/ListItem';

const useStyles = makeStyles(theme => ({
  root: props => ({
    backgroundColor: props.background_color || 'transparent',
    color: props.text_color || '#000000'
  }),
  container: {
    paddingTop: 72,
    paddingBottom: 72,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 60,
      paddingBottom: 44
    }
  },
  description: {
    '& > h2': {
      marginTop: 32,
      marginBottom: 32,
      fontSize: 52,
      fontFamily: 'Inter',
      fontWeight: '700',
      lineHeight: '122%',
      [theme.breakpoints.down('xs')]: {
        fontSize: 35,
        fontWeight: '600',
        marginTop: 15,
        marginBottom: 10
      }
    },
    '& > :first-child': {
      marginTop: '0px !important'
    },
    '& > p': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '400',
      lineHeight: '170%',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },
    '& > p:empty': {
      height: '22px'
    },
    '& > * > strong': {
      fontWeight: '700'
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 32
    }
  },
  textUnderCta: {
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '500',
      lineHeight: '170%',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },
    '& > * > a': {
      color: props => props.text_color || '#000000',
      fontWeight: '800',
      textDecoration: 'underline'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '360px'
    }
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5
  },
  actionContainer: {
    marginTop: 48,
    display: 'flex',
    flexDirection: 'column',
    gap: 26,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 30,
      gap: 13,
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  ctaButton: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '286px'
    }
  }
}));

const TishaBavDescription = ({
  content,
  text_color,
  background_color,
  cta_path,
  cta_text,
  cta_theme,
  text_under_cta,
  list
}) => {
  const classes = useStyles({
    text_color,
    background_color
  });

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {content?.html && (
          <RichText
            html={content?.html}
            externalClassName={classes.description}
            verticalSpacing={0}
          />
        )}

        {list?.length && (
          <div className={classes.listContainer}>
            {list.map(item => (
              <ListItem key={item?.list_item?.text} {...item} />
            ))}
          </div>
        )}

        <div className={classes.actionContainer}>
          {cta_path && (
            <RoundedLinkButton
              text={cta_text}
              to={cta_path}
              shouldTrackAnalytics
              analyticsEvent="Tisha Bav Button Click"
              analyticsTitle={cta_text}
              analyticsSection="TB Event Hero"
              size="small"
              variant={cta_theme}
              externalClassName={classes.ctaButton}
            />
          )}
          {text_under_cta?.html && (
            <RichText
              html={text_under_cta?.html}
              externalClassName={classes.textUnderCta}
              verticalSpacing={0}
            />
          )}
        </div>
      </Container>
    </section>
  );
};

TishaBavDescription.propTypes = {
  content: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  background_color: PropTypes.string.isRequired,
  text_color: PropTypes.string.isRequired,
  cta_path: PropTypes.string.isRequired,
  cta_text: PropTypes.string.isRequired,
  cta_theme: PropTypes.string.isRequired,
  text_under_cta: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      heading_text: PropTypes.string,
      heading_text_color: PropTypes.string,
      list_item_color: PropTypes.string,
      list_item_icon: PropTypes.oneOf(['none', 'clock', 'calendar']),
      list_item: PropTypes.shape({
        html: PropTypes.string,
        text: PropTypes.string
      })
    })
  )
};

TishaBavDescription.defaultProps = {
  text_under_cta: null,
  list: []
};

export default TishaBavDescription;
